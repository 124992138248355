import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { FirebaseAppProvider } from 'reactfire';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ThemeProvider, createTheme } from '@mui/material';

import firebase_config from './firebase/firebase_config';

import 'styles/styles.scss';

import LoadingSpinner from './components/LoadingSpinner';

import { palette } from 'styles/palette';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { mockDatabaseData } from 'tests/API_mocks/i3d';

import { LicenseInfo } from '@mui/x-license';


// import { ReCaptchaEnterpriseProvider, initializeAppCheck } from 'firebase/app-check';

const FirebaseComponents = lazy(() => import('./firebase/FirebaseComponents'));
const AuthDataProvider   = lazy(() => import('app/providers/AuthDataProvider'));
const ScrollToTop        = lazy(() => import('app/ScrollWrapper'));
const AppContent         = lazy(() => import('./app/AppContent'));



const theme = createTheme({
	spacing: 8,
	typography: {
		// In Chinese and Japanese the characters are usually larger,
		// so a smaller fontsize may be appropriate.
		fontSize: 12
	},
	palette: palette
});

const queryClient = new QueryClient();

// Wraps everything in an app provider, which makes the Firebase app available everywehre
const App = () =>
{

	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY ?? 'Key Not Available');

	// Generic error handler for ResizeObserver errors that keep popping up from ResizeObservers
	useEffect(() =>
	{
		// Generic error handler for ResizeObserver errors thrown by Timeline
		const handleObserverError = (e: ErrorEvent) => {
			if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.' || e.message === 'ResizeObserver loop completed with undelivered notifications.')
			{
				const resizeObserverErrDiv = document.getElementById(
					'webpack-dev-server-client-overlay-div'
				);
				const resizeObserverErr = document.getElementById(
					'webpack-dev-server-client-overlay'
				);
				if (resizeObserverErr)
				{
					resizeObserverErr.setAttribute('style', 'display: none');
				}
				if (resizeObserverErrDiv)
				{
					resizeObserverErrDiv.setAttribute('style', 'display: none');
				}
			}
		};

		// Add observer error handler to window object
		window.addEventListener('error', e => handleObserverError(e));

		// Cleanup observers on unmount
		return () => window.removeEventListener('error', e => handleObserverError(e));
	}, []);

	if (process.env.REACT_APP_TEST === 'true') mockDatabaseData();

	return (
		<ThemeProvider theme={theme}>
			<FirebaseAppProvider
				firebaseConfig={firebase_config()}
				suspense={true}
			>

				<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
					useEnterprise={true}
					scriptProps={{
						defer: true
					}}
				>

					<QueryClientProvider client={queryClient}>
						<ReactQueryDevtools initialIsOpen={false} />
						<Suspense
							fallback={<LoadingSpinner />}
						>
							<FirebaseComponents>
								<AuthDataProvider>
									<BrowserRouter >
										<ScrollToTop />
										<AppContent />
									</BrowserRouter >
								</AuthDataProvider>
							</FirebaseComponents>
						</Suspense>
					</QueryClientProvider>
				</GoogleReCaptchaProvider>
			</FirebaseAppProvider>
		</ThemeProvider>
	);
};

export default App;
