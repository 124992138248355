
// TODO: Determine how / where to store this mock data, this is really not ideal
const landing_page_data = {
	'data': {
		'featured_products': [],
		'recently_ordered': [],
		'recent_products': [],
		'categories': [
			{
				'id': 22,
				'name': 'Caps and Connectors',
				'description': null,
				'image_path': null,
				'bucket_name': null
			},
			{
				'id': 29,
				'name': 'Clips and Clamps',
				'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
				'image_path': null,
				'bucket_name': null
			},
			{
				'id': 21,
				'name': 'Containers',
				'description': null,
				'image_path': null,
				'bucket_name': null
			},
			{
				'id': 10,
				'name': 'Organizational Tools',
				'description': null,
				'image_path': null,
				'bucket_name': null
			}
		]
	}
};

const user_data = {
	'data': {
		'id': 1,
		'display_name': 'Clark Oake',
		'email': 'clark@polyunity.com',
		'id_string': 'mfAon52wXGbi06kH4YsU0jQAKZI3',
		'organisation': {
			'id': 22,
			'name': 'CHEO (Children\'s Hospital of Eastern Ontario)',
			'short_name': 'cheo'
		}
	}
};

const account_page_data = {
	'data': [
		{
			'id': 1,
			'name': 'polyunity_admin'
		},
		{
			'id': 2,
			'name': 'org_admin'
		},
		{
			'id': 3,
			'name': 'org_auditor'
		},
		{
			'id': 4,
			'name': 'department_manager'
		},
		{
			'id': 5,
			'name': 'user'
		}
	]
};

const cart_data = {
	'data': [
		{
			'product_id': 2635,
			'department_id': 583,
			'quantity': 1,
			'user_id': 2649,
			'note': '',
			'product': {
				'name': 'notAProduct',
				'id_string': 'C002',
				'file_required': false,
				'is_approved': false,
				'remaining_quantity': null,
				'price': 0,
				'pending_order_items': null,
				'images': [],
				'materials': [
					{
						'id': 1,
						'name': 'Silicone',
						'quantity': null
					},
					{
						'id': 2,
						'name': 'PLA',
						'quantity': null
					}
				]
			},
			'department': {
				'id': 583,
				'name': 'Marketing',
				'client_id': 'not mapped'
			}
		},
		{
			'product_id': 2810,
			'department_id': 147,
			'quantity': 1,
			'user_id': 2649,
			'note': '',
			'product': {
				'name': 'Rear Hanger Hook for P500 Therapy Air Surface',
				'id_string': 'C152B',
				'file_required': false,
				'is_approved': false,
				'remaining_quantity': null,
				'price': null,
				'pending_order_items': null,
				'images': [],
				'materials': [
					{
						'id': 2,
						'name': 'PLA',
						'quantity': 8
					}
				]
			},
			'department': {
				'id': 147,
				'name': 'Orthopedics',
				'client_id': ''
			}
		},
		{
			'product_id': 2635,
			'department_id': 147,
			'quantity': 3,
			'user_id': 2649,
			'note': '  ',
			'product': {
				'name': 'notAProduct',
				'id_string': 'C002',
				'file_required': false,
				'is_approved': false,
				'remaining_quantity': null,
				'price': 0,
				'pending_order_items': null,
				'images': [],
				'materials': [
					{
						'id': 1,
						'name': 'Silicone',
						'quantity': null
					},
					{
						'id': 2,
						'name': 'PLA',
						'quantity': null
					}
				]
			},
			'department': {
				'id': 147,
				'name': 'Orthopedics',
				'client_id': ''
			}
		}
	]
};

const user_idea_data = {
	'data': [
		{
			'id': 2690,
			'created_on': '2023-12-07T19:47:43.995Z',
			'is_dummy': false,
			'idea_information': 'wrggsg',
			'reason': 'wrgrgrg',
			'suggested_name': 'wrgagae',
			'reusable': false,
			'id_string': null,
			'date_triaged': null,
			'reviewed_on': '2024-04-23T18:07:13.895Z',
			'is_estimate_approved': true,
			'date_requested': '2023-12-07 19:47:43.995843+00',
			'estimated_cost': null,
			'sanitizations': [],
			'files': [],
			'child_products': [],
			'hidden_products': [],
			'reviewer': {
				'id': 223,
				'display_name': 'Clark Oake'
			}
		},
		{
			'id': 2612,
			'created_on': '2023-08-02T14:37:13.634Z',
			'is_dummy': false,
			'idea_information': 'bd vacutainer racks',
			'reason': '',
			'suggested_name': '',
			'reusable': false,
			'id_string': null,
			'date_triaged': null,
			'reviewed_on': null,
			'is_estimate_approved': null,
			'date_requested': '2023-05-16 11:21:31.374+00',
			'estimated_cost': null,
			'sanitizations': [],
			'files': [],
			'child_products': [],
			'hidden_products': [],
			'reviewer': null
		}
	]
};

const idea_form_data = {
	'message': 'Successfully retrieved data',
	'data': {
		'specifications': [],
		'statuses': [],
		'sanitizations': [],
		'organisations': [],
		'assignees': [],
		'all_shelved_reasons': []
	}
};

const org_depts_data = {
	'message': 'Successfully retrieved data',
	'data': [
		{
			'id': 44,
			'created_on': '2023-04-22T15:09:38.580Z',
			'created_by': 1,
			'changed_on': '2024-09-18T16:23:43.405Z',
			'changed_by': 284,
			'is_deleted': false,
			'name': 'Endocrinology',
			'id_string': 'endocrinology_polyunity',
			'organisation_id': 11,
			'organisation_string': 'polyunity',
			'client_id': '',
			'client_name': 'Endocrinology',
			'current_approver_id': 54,
			'department_users': [
				{
					'id': 149,
					'display_name': 'Sydney Newton',
					'email': 'sydney.newton@polyunity.com',
					'is_budget_approver': false,
					'is_product_approver': false
				},
				{
					'id': 1086,
					'display_name': 'Sydney Test',
					'email': 'sydney.newton+testprod@polyunity.com',
					'is_budget_approver': false,
					'is_product_approver': false
				}
			],
			'current_approvers': [
				{
					'id': 37,
					'display_name': 'Dan Brake',
					'email': 'dan@polyunity.com',
					'is_budget_approver': true,
					'is_product_approver': true
				}
			]
		}
	]
};

const product_data = {
	'message': 'Successfully retrieved Product data',
	'data': {
		'id': 2641,
		'created_on': '2023-03-22T12:33:05.669Z',
		'created_by': 1,
		'changed_on': '2024-06-05T13:56:52.397Z',
		'changed_by': 2649,
		'story': '',
		'catalog_description': 'Extender for PolyUnity Procedural Face Shields. Provides extra space between face and shield for Loupes.',
		'approval_reason': '',
		'name': 'Samson Microphone Stand',
		'status': {
			'id': 8,
			'name': 'SHELVED'
		},
		'default_design_hrs': 0,
		'default_print_hrs': 0,
		'default_labour_hrs': 0,
		'date_completed_on': null,
		'parent_product_id': null,
		'id_string': 'C008',
		'design_note': 'Shelved: no longer needed by requestors',
		'compatible_product': null,
		'operating_conditions': '< 40°C',
		'product_price': 22,
		'file_required': false,
		'approval_description': '',
		'third_party_org_id': null,
		'compatible_equipment': null,
		'total_delivery_design_hrs': 46,
		'timesheet_hours': null,
		'o': [
			{
				'id': 22,
				'print_per_hr': 12,
				'labour_per_hr': 140
			}
		],
		'pending_order_items': null,
		'product_approvals': [],
		'catalogs': [],
		'files': [],
		'categories': [
			{
				'id': 10,
				'name': 'Organizational Tools'
			}
		],
		'materials': [
			{
				'id': 2,
				'name': 'PLA',
				'quantity': null
			}
		],
		'collaborators': [],
		'assigned_to': {
			'id': 321,
			'name': ''
		},
		'tags': [
			{
				'id': 2,
				'name': 'INNOVATE'
			}
		],
		'links': [
			{
				'id': 726,
				'name': 'Samson Microphone Stand',
				'path': 'test',
				'type': 'link'
			}
		],
		'organisations': [
			{
				'id': 22,
				'name': 'CHEO (Children\'s Hospital of Eastern Ontario)',
				'is_featured': true
			},
			{
				'id': 25,
				'name': 'PolyUnity Tech Inc.',
				'is_featured': false
			}
		],
		'children': [],
		'supplier': {
			'id': 25,
			'name': 'PolyUnity Tech Inc.'
		},
		'parent': null,
		'completor': null,
		'ideas': [
			{
				'id': 2190,
				'is_dummy': false,
				'submitted_by_department_id': 54,
				'submitted_by': 283,
				'id_string': 'C008',
				'level_of_effort': 0,
				'name': 'Samson Microphone Stand',
				'submitted_by_org_id': 22,
				'submitter_name': 'Oem Dave',
				'department_name': 'Biomedical'
			}
		],
		'order_with': [],
		'related_products': [],
		'product_files': [],
		'case_study': null,
		'approved_in_orgs': [],
		'carts': [],
		'originating_idea': {
			'id': 2190,
			'is_dummy': false,
			'submitted_by': 283,
			'id_string': 'C008',
			'level_of_effort': 0,
			'name': 'Samson Microphone Stand',
			'submitter': {
				'id': 283,
				'name': 'test'
			},
			'department': {
				'id': 54,
				'name': 'Biomedical',
				'organisation_id': 22
			}
		},
		'form_collection_path': null,
		'price': 22,
		'is_approved': false,
		'remaining_quantity': null
	}
};

const order_form_data = {
	'message': 'Successfully retrieved form data',
	'data': {
		'equipments': [],
		'addresses': [],
		'organisations': [],
		'materials': [],
		'statuses': [],
		'related_idea_join': {
			'idea_id': 2190,
			'product_id': 2641,
			'default_receiver': []
		},
		'items': []
	}
};

const secret_data = {
	'result': {
		'secret_value': 'super_secret_variable'
	}
};

const category_data = {
	'message': 'Successfully retrieved data',
	'data': [
		{
			'id': 22,
			'name': 'Containers'
		},
		{
			'id': 27,
			'name': 'Otolaryngology (ENT)'
		},
		{
			'id': 6,
			'name': 'Fixation Devices'
		},
		{
			'id': 20,
			'name': 'Services'
		},
		{
			'id': 7,
			'name': 'Splints and Casts'
		},
		{
			'id': 13,
			'name': 'Replacement Parts'
		}
	]
};

const status_data = {
	'message': 'Successfully retrieved data',
	'data':  {
		'ideastatus': [
			{
				'name': 'ARCHIVED'
			},
			{
				'name': 'CONTACTED'
			},
			{
				'name': 'NEW'
			},
			{
				'name': 'PROMOTED'
			},
			{
				'name': 'SHELVED'
			},
			{
				'name': 'TRIAGE'
			}
		],
		'productstatus': [
			{
				'name': 'ARCHIVED'
			},
			{
				'name': 'COMPLETE'
			},
			{
				'name': 'DEVELOPMENT'
			},
			{
				'name': 'LAUNCH PREP'
			},
			{
				'name': 'RE-EVALUATING'
			},
			{
				'name': 'SHELVED'
			},
			{
				'name': 'USER TESTING'
			}
		],
		'orderstatus': [
			{
				'name': 'CANCELLED'
			},
			{
				'name': 'DELIVERED'
			},
			{
				'name': 'DELIVERING'
			},
			{
				'name': 'IN PROGRESS'
			},
			{
				'name': 'NEW'
			},
			{
				'name': 'PRODUCED'
			}
		]
	}
};

export const mockDatabaseData = () => {
	const { fetch: original_fetch } = window;
	window.fetch = async (...args) => {
		const [url] = args;

		const endpoint = (url as string).replace('http://127.0.0.1:5001/poly-dev-cmsd/northamerica-northeast1/', '');
		console.log('fetch intercepted for endpoint:', endpoint);

		const matchedMockResponse = Object.keys(mockResponses).find(key => endpoint.startsWith(key));

		if (matchedMockResponse) {
			/* mock the response: */
			return new Response(JSON.stringify(mockResponses[matchedMockResponse]));
		} else {
			/* or work with the cloned response in a separate promise
			chain -- could use the same chain with `await`. */
			const response = await original_fetch(...args);

			response
				.clone()
				.json()
				.then(data => console.log('intercepted response data:', data))
				.catch(err => console.error(err));

			/* the original response can be resolved unmodified: */
			return response;
		}
	};
};

const mockResponses: any = {
	'getUser': user_data,
	'getCart': cart_data,
	'getIdeas': user_idea_data,
	'getLandingPage': landing_page_data,
	'getOrgDepts': org_depts_data,
	'getStatuses': status_data,
	'getCatalogCategories': category_data
};

// TODO: determine how we can mock Firestore (or at least its queries) for testing
interface MockResponse {
    status: number;
    contentType: string;
    headers: Record<string, string>;
    body?: string;
}

// Default response object for successful mock API requests
const mockResponse: MockResponse = {
	status: 200,
	contentType: 'application/json',
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization'
	}
};

// TODO: We may need to review how we are matching URLs for better accuracy
const mockAPIRoutes: Record<string, MockResponse> = {
	'/getLandingPage': {
		...mockResponse,
		body: JSON.stringify(landing_page_data)
	},
	'/getSecretFromCloud': {
		...mockResponse,
		body: JSON.stringify(secret_data)
	},
	'/getAllUserRoles': {
		...mockResponse,
		body: JSON.stringify(account_page_data)
	},
	'/getUserIdeas': {
		...mockResponse,
		body: JSON.stringify(user_idea_data)
	},
	'/getUser': {
		...mockResponse,
		body: JSON.stringify(user_data)
	},
	'/getIdeaForm': {
		...mockResponse,
		body: JSON.stringify(idea_form_data)
	},
	'/getCart': {
		...mockResponse,
		body: JSON.stringify(cart_data)
	},
	'/getOrgDepts': {
		...mockResponse,
		body: JSON.stringify(org_depts_data)
	},
	'/getProductSecondGen': {
		...mockResponse,
		body: JSON.stringify(product_data)
	},
	'/getOrderForm': {
		...mockResponse,
		body: JSON.stringify(order_form_data)
	}
};

// TODO: Fix typing on this
export async function setupRequestInterception(page: any, mocks = mockAPIRoutes) {
	await page.setRequestInterception(true);

	page.on('request', (interceptedRequest: any) => {
		const url = interceptedRequest.url();

		// Check if the request matches one of the mock endpoints
		const mockKey = Object.keys(mocks).find((key) => url.includes(key));
		if (mockKey) {
			// console.log(`Mocking request for: ${mockKey}`);  
			interceptedRequest.respond(mocks[mockKey]);
		} else {
			interceptedRequest.continue();
		}
	});
}
